import React,{useEffect,useContext} from "react"
import {Switch,Route} from "react-router-dom";
import Portada from "./portada"
import List from "./app/cursos/list"
import Start from "./app/cursos/curso1"
import Curso from "./app/cursos/curso"
import Login from "./app/login/login"
import Register from "./app/login/register"
export default function Main(){
    
  return(
    <Switch>
      <Route path="/about">
        <Portada/>
      </Route>
      <Route path="/login">
        <Login/>
      </Route>
      <Route path="/register">
        <Register/>
      </Route>
      
      <Route path="/curso/:id" render={(props) => <Curso {...props} />} />
      <Route path="/list">
        <List/>
      </Route>
      <Route path="/">
        <Start/>
      </Route>
      <Route path="/404">
        <p>error</p>
      </Route>
    </Switch>
  )
}