import React,{useEffect,useContext} from "react"


export default function Main(){
    
    return(
       <div>
           <footer className="footer" id="footer">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="footer-info-area">
                    <div className="footer-logo">
                      <a href="#" className="logo-link">
                        <img src="assets/images/logo-color.png" alt="" />
                      </a>
                    </div>
                    <div className="text">
                      <p>Unete y disfruta de una nueva manera de recibir cursos online. 
                      </p>
                    </div>
                  </div>
                  <div className="fotter-social-links">
                    <ul>
                      <li>
                        <a href="#" className="facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" className="twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" className="linkedin">
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </li>
                      <li>
                        <a href="#" className="google-plus">
                          <i className="fab fa-google-plus-g" />
                        </a>
                      </li>
                      <li>
                        <a href="#" className="dribbble">
                          <i className="fab fa-dribbble" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="copy-bg text-center">
              <p>Copyright © 2021. Todos los derechos reservados por SHA PERU
              </p>
            </div>
          </footer>
       </div>
        
    )
}