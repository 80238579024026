import React,{useEffect,useContext,useState } from "react"
import axios from 'axios'
import InfoContext from "../../global/InfoContext"
import Global from '../../global/Global'
import Portada from "../../components/portada"
const API = Global.APILocal

export default function Main(props){
  const { id } = props.match.params
  const [modulos,setModulos]=useState([]);
  const [tema,setTema]=useState();
  const [lessons,setLessons]=useState(null);
  const GetModulos = async () => {
    
    //setLoading([true,"Cargando tema"]);
    try {
        const res_tema=await axios({method: 'get',url:API+"client/tema/"+id})
        const response_tema = res_tema.data.tema
        console.log(res_tema.data.suscription)
        //setSuscription(res_tema.data.suscription)
        setTema(response_tema);

        console.log(response_tema);

        const res_modulos=await axios({method: 'get',url:API+"client/modulo/tema/"+id})
        const response_modulos = res_modulos.data
        setModulos(response_modulos);

        console.log(response_modulos);

        const res_Lessons=await axios({method: 'get',url:API+"client/leccion/tema/"+id})
        const response_Lessons = res_Lessons.data
        setLessons(response_Lessons);

        console.log(response_Lessons);
        //console.log(jwt)
    } catch (e) {
        console.warn(e.response)
        //toastRef.current.show("Error en tu conexión de internet")
    }
    //setLoading([false]);        
  }
  useEffect(() => {
      
      GetModulos()
  }, []);
  if(tema==null) return(null)
  return(
      <div>
        <Portada name={tema.nombre}/>
        <section className="blog-page single-blog-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="single-blog-details">
                  <div className="img">
                    <img src="assets/images/blog/blog-details.png" alt="" />
                  </div>
                  
                  <div className="content">
                    <div className="text-area">
                      <p>{tema.informacion1}</p>
                      <p>SILABUS</p>
                      <p>.</p>
                      

                      {modulos?modulos.map((modulo) => (
                        <div>
                          <p>{modulo.nombre}</p>
                          <ul>
                            {lessons?lessons.map((lesson) => (
                              <div>
                                {lesson.moduloid==modulo._id?
                                <li><p><i className="far fa-hand-point-right" />{lesson.nombre}</p></li>:null
                              }
                              </div>
                              
                              )):null}
                          </ul>
                      </div>
                      )):<p>no tienes devices pupi</p>}
                      
                      <p>{tema.informacion1}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="categori-widget">
                  <h4 className="title">
                    Categories
                  </h4>
                  <ul className="cat-list">
                    <li>
                      <a href="#">
                        <p>
                          Trading
                        </p>
                        <span className="count">
                          33
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <p>
                          Exchange
                        </p>
                        <span className="count">
                          77
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <p>
                          Guide
                        </p>
                        <span className="count">
                          49
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <p>
                          Blockchain
                        </p>
                        <span className="count">
                          70
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <p>
                          Crypto
                        </p>
                        <span className="count">
                          33
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <p>
                          Lending
                        </p>
                        <span className="count">
                          38
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="latest-post-widget">
                  <h4 className="title">
                    Latest Post
                  </h4>
                  <ul className="post-list">
                    <li>
                      <div className="post">
                        <div className="post-img">
                          <img src="assets/images/blog/img1.png" alt="" />
                        </div>
                        <div className="post-details">
                          <a href="#" className="post-title">
                            5 reasons why you website  
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="post">
                        <div className="post-img">
                          <img src="assets/images/blog/img2.png" alt="" />
                        </div>
                        <div className="post-details">
                          <a href="#" className="post-title">
                            6 reasons why you website 
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="post">
                        <div className="post-img">
                          <img src="assets/images/blog/img3.png" alt="" />
                        </div>
                        <div className="post-details">
                          <a href="#" className="post-title">
                            7 reasons why you website 
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="post">
                        <div className="post-img">
                          <img src="assets/images/blog/img4.png" alt="" />
                        </div>
                        <div className="post-details">
                          <a href="#" className="post-title">
                            8 reasons why you website 
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="newsletter-widget">
                  <h4 className="title">
                    NewsLetter
                  </h4>
                  <form action="#">
                    <input type="text" className="input-field" placeholder="Enter Email.." />
                    <button type="submit" className="mybtn2">
                      <span>Subscribe</span>
                    </button>
                  </form> 
                </div>
              </div>
            </div>
          </div>
        </section>

       </div>
        
    )
}