import React,{useEffect,useState,useContext} from "react"
import Portada from "../../components/portada"
import Curso from "../../components/curso"
import axios from 'axios'
import InfoContext from "../../global/InfoContext"
import Global from '../../global/Global'
const API = Global.APILocal

export default function Main(){
  const {} =useContext(InfoContext)
  const [list,setList]=useState(null);
  const [instructor,setInstructor]=useState(null);
  const GetData = async () => {
        
    try {
        //setLoading([true,"Cargando datos"]);
        

        const res_temas =await axios({method:'get',url:API+"client/tema"})
        const response_temas = res_temas.data
        setList(response_temas);
        console.log(response_temas);
        
        const res_instructor =await axios({method:'get',url:API+"client/instructor"})
        const response_instructor = res_instructor.data
        setInstructor(response_instructor);
        
        
    } catch (e) {
        //toastRef.current.show("Error en servidor")
        console.warn(e.response)
    }
    //setLoading([false]);        
  }

  useEffect(() => {
    GetData()
    
  }, []);

  return(
    
    <div>
      <Portada name="Nuestros cursos"/>
      <section className="blog-page single-blog-area">
        <div className="container">
          <div className="row">

            
            {list?list.map((curso) => (
              <Curso name={curso.nombre} id={curso._id}/>
            )):<p>no tienes devices pupi</p>}
          </div>
        </div>
      </section>
    </div>
    
  )
}