import React,{useEffect,useContext} from "react"
import {Link} from "react-router-dom";

export default function Main(){
    
  return(
    <div>
      <div id="home" className="hero-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-self-center">
              <div className="left-content">
                <div className="content">
                  <h1 className="title">
                    Cursos tecnologicos en vivo
                  </h1>
                  <p className="subtitle">
                    Unete  y disfruta de una nueva manera de recibir cursos online.
                  </p>
                  <div className="links">
                    <Link to="/list" className="mybtn3 mybtn-light"><span>Ver cursos</span> </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-first order-lg-last">
              <div className="right-img">
                <img className="img-fluid img" src="assets/images/w4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    
  )
}