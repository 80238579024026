import React,{useEffect,useContext} from "react"


export default function Main(){
    
  return(
    <div>
      <section className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="title">
                Blog Details With Sidebar
              </h4>
              <ul className="breadcrumb-list">
                <li>
                  <a href="index.html">
                    <i className="fas fa-home" />
                    Home
                  </a>
                </li>
                <li>
                  <span><i className="fas fa-chevron-right" /> </span>
                </li>
                <li>
                  <a href="blog-details-with-sidebar.html">Blog Details With Sidebar</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
      
  )
}