import React,{useEffect,useContext} from "react"


export default function Main(){
    
  return(
    <div>
      <section className="auth">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10">
              <div className="sign-form">
                <div className="heading">
                  <h4 className="title">
                    Create account
                  </h4>
                  <p className="subtitle">
                    Made with love by developers for developers.
                  </p>
                </div>
                <form className="form-group mb-0" action="#">
                  <input className="form-control " type="text" name="yourname" placeholder="Enter User Name" />
                  <input className="form-control " type="email" name="email" placeholder="Email Address" />
                  <input className="form-control" type="password" name="password" placeholder="Password" />
                  <input className="form-control" type="password" name="c-password" placeholder="Confirm  Password" />
                  <div className="custom-control custom-checkbox  d-flex">
                    <input type="checkbox" className="custom-control-input" id="remember" />
                    <label className="custom-control-label ml-2" htmlFor="remember">I agree to the <a href="#">terms and conditions</a> </label>
                  </div>
                  <button className="bigbtn" type="submit">Create Account</button>
                  <p className="reg-text text-center mb-0">Already have an acocunt? <a href="login.html">LogIn</a></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    
  )
}