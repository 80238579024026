import logo from './logo.svg';
import './App.css';
import Route from "./Route"
import Footer from "./components/footer"

import Inicio from "./components/inicio"

import {BrowserRouter as Router} from "react-router-dom";

function App() {
  return (
    <Router>
      <Inicio/>
      <Route/>
      <Footer/>
    </Router>
  );
}

export default App;