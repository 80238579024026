import React,{useEffect,useContext} from "react"
import {Link} from "react-router-dom";

export default function Main(){
    
  return(
    <div>
      <header className="navigation stiky-nav">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 p-0">
              <nav className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to="/">
                  <img src="assets/images/logo-color.png" alt="" />
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainmenu" aria-controls="mainmenu" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="mainmenu">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <Link to="/" className="nav-link" >Home </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/list">Cursos</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/curso">Curso</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/login" className="nav-link" >Login </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/register" className="nav-link" >Registro </Link>
                    </li>
                    
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
      
  )
}