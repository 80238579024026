import React,{useEffect,useContext} from "react"
import {Link} from "react-router-dom";
import Global from '../global/Global'
const BucketUrl = Global.BucketUrl
export default function Main(props){
  const{name,id}=props;

  return(
    <div className="col-lg-4 col-md-6">
      <div className="single-blog">
        <div className="img">
          <img src={BucketUrl+"temas/tema"+id+".jpg"} alt="" />
        </div>
        <div className="content">
        <Link className="nav-link" to={"/curso/"+id}><h4 className="title">{name}</h4></Link>
        </div>
      </div>
    </div>
      
  )
}